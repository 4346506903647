import AnchorLink from '@app/components/ui/links/anchor-link';
import SiretoLogo from '@app/assets/images/sireto-logo-dark.png';
import React from 'react';
import ImageRenderer from '@app/components/media-renderer/image-renderer';
import LetsTalk from '@app/components/ui/lets-talk';
import { ILayoutProps } from '@app/models/props/home';
import environments from '@app/configs/environments';
import { capitalize } from '@app/utils/stringUtils';

export default function Footer(props: ILayoutProps) {
    const { locations, settings, socialMedias, products } = props;
    return (
        <div className="flex w-full flex-col items-center">
            <LetsTalk locations={locations} />
            <div className=" flex w-full flex-col items-center bg-black-1">
                <div className=" container ">
                    <div className="my-[60px] flex w-full flex-col justify-between gap-10 md:flex-row">
                        <div className="h-[67px] w-[203px]">
                            <ImageRenderer media={SiretoLogo} className="!object-contain filter-none" isGrayScale={false} />
                        </div>
                        <div className="flex h-full max-w-[810px] flex-col">
                            <div className="flex flex-col gap-x-[100px]  gap-y-12 md:flex-row lg:gap-x-[198px]">
                                <div>
                                    <p className="P2 mb-6 !text-white-2">Quick links</p>
                                    <div className="P2 flex flex-col gap-4 !text-white">
                                        <AnchorLink href="/">Home</AnchorLink>
                                        {settings?.navigation &&
                                            settings?.navigation.length !== 0 &&
                                            settings?.navigation.map((navigation) => {
                                                if (navigation.label.toLowerCase() === 'products') return;
                                                return (
                                                    <AnchorLink key={navigation.url} href={navigation.label.toLowerCase() === 'blogs' ? `${environments.GHOST_HOST_URL}` : navigation.url}>
                                                        {navigation.label}
                                                    </AnchorLink>
                                                );
                                            })}
                                    </div>
                                </div>
                                <div>
                                    <p className="P2 mb-6 !text-white-2">Products</p>
                                    <div className="P2 flex flex-col gap-4 !text-white">
                                        {products &&
                                            products.length !== 0 &&
                                            products.slice(0, 3).map((product) => (
                                                <AnchorLink key={product.id} href={`/product/${product.attributes.name.toLowerCase()}`}>
                                                    {product.attributes.name}
                                                </AnchorLink>
                                            ))}
                                    </div>
                                </div>
                                {socialMedias && socialMedias.length !== 0 && (
                                    <div>
                                        <p className="P2 mb-6 !text-white-2">Social Media</p>
                                        <div className="P2 flex flex-col gap-4 !text-white">
                                            {socialMedias.map((socialMedia) => (
                                                <AnchorLink key={socialMedia.id} href={socialMedia.attributes.url} className="flex  items-center gap-2" target="_blank">
                                                    {socialMedia.attributes.icon?.data && (
                                                        <div className="!h-[18px] !w-[18px]">
                                                            <ImageRenderer media={environments.STRAPI_HOST_URL + socialMedia.attributes.icon.data.attributes.url} isGrayScale={false} className="filter-none" />
                                                        </div>
                                                    )}
                                                    <span>{capitalize(socialMedia.attributes.name)}</span>
                                                </AnchorLink>
                                            ))}
                                        </div>
                                    </div>
                                )}
                            </div>
                            <hr className="my-[60px] h-[1px] w-full bg-black"></hr>
                            <div id="locations" className="flex flex-col gap-[50px] md:flex-row md:gap-[200px]">
                                {locations &&
                                    locations.map((location) => {
                                        const { country, address, postalCode, phone, email, others } = location.attributes;
                                        return (
                                            <div key={location.id} className="P1 flex flex-col gap-2 !text-white-2 ">
                                                <p className="P2 !text-white"> {country}</p>
                                                <div>
                                                    <p>{address}</p>
                                                    <p>{postalCode}</p>
                                                </div>
                                                <p>{phone}</p>
                                                {email && <AnchorLink href={`mailto:${email}`}>{email}</AnchorLink>}
                                                {others && others.length != 0 && others.map((other) => <p key={other.id}>{other.title}</p>)}
                                            </div>
                                        );
                                    })}
                            </div>
                        </div>
                    </div>
                </div>
                <hr className="h-[1px] w-full bg-black"></hr>
                <div className="container flex w-full items-center justify-between">
                    <div className="P2 py-8 text-center !text-white-2">&#169; Sireto Technology. All Rights Reserved</div>
                    {/*<AnchorLink href="" className="text-blue">{locations && locations[0].attributes.email}</AnchorLink>*/}
                </div>
            </div>
        </div>
    );
}
